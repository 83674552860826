window.animations = {
    documentHeight: Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight),
    windowHeight: window.innerHeight,
    items: document.querySelectorAll('.jsAnimate'),
    activeClass: 'is-animate',

    init: function () {
        const items = window.animations.items;
    
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            !item.getAttribute('data-observe-percent') ? item.setAttribute('data-observe-percent', '0') : '';
        }
    },
  
    update: function (){
        const items = window.animations.items;
        let windowOffsetTop = window.pageYOffset;
        let windowOffsetBottom = windowOffsetTop + window.animations.windowHeight;

        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            let mode = item.getAttribute('data-observe-mode') ? item.getAttribute('data-observe-mode') : '1';
            let percent = item.getAttribute('data-observe-percent');
            let triggerPercent = item.getAttribute('data-observe-trigger') ? item.getAttribute('data-observe-trigger') : 25;
            let windowMultiplier = 1;


            // SIZES 
                            
            let itemHeight = item.offsetHeight;
            let itemOffsetTop = item.offsetTop;
            let itemOffsetBottom = itemOffsetTop + itemHeight;
            let innerTop = itemOffsetBottom - windowOffsetTop;
            let innerBottom = windowOffsetBottom - itemOffsetTop;

            if (window.animations.windowHeight < itemHeight) {
                windowMultiplier = window.animations.windowHeight / itemHeight;
            }

            if (itemOffsetBottom >= windowOffsetTop && windowOffsetBottom >= itemOffsetTop) {
                let resultPercent = percent;

                if (mode == '1') {
                    resultPercent = mode1()
                } else if (mode == '2') {
                    resultPercent = mode2()
                } else if (mode == '3') {
                    resultPercent = mode3()
                } else {
                    resultPercent = mode1()
                }

                updateValues(resultPercent)
            }

            function mode1() {  // MODE UpdateOneTime
                if (innerBottom <= itemHeight * windowMultiplier) {
                    let temp = (innerBottom / itemHeight * 100) / windowMultiplier;

                    if (percent <= temp) {
                        percent = temp
                    }
                } else if (innerTop <= itemHeight * windowMultiplier) {
                    let temp = (innerTop / itemHeight * 100) / windowMultiplier;

                    if (percent <= temp) {
                        percent = temp
                    }
                } else {
                    percent = 100;
                }

                return percent;
            }
     
            function mode2() { // MODE UpdateOnlyDown
                if (innerBottom <= itemHeight) {
                    percent = (innerBottom / itemHeight * 100) / windowMultiplier;
                } else {
                    percent = 100;
                }
                
                return percent;
            }

            function mode3() { // MODE UpdateAlways
                if (innerBottom <= itemHeight * windowMultiplier) {
                    percent = (innerBottom / itemHeight * 100) / windowMultiplier;
                } else if (innerTop <= itemHeight * windowMultiplier) {
                    percent = (innerTop / itemHeight * 100) / windowMultiplier;
                } else {
                    percent = 100;
                }

                return percent;
            }

            function updateValues(percent) { 
                percent = Math.round(percent);
                item.setAttribute('data-observe-percent', percent)
                
                actions(percent)
            }

            function actions(percent) {
                if(percent >= triggerPercent){
                    item.classList.add(window.animations.activeClass)
                } else{
                    item.classList.remove(window.animations.activeClass)
                }
            }
        }
    }
    
    
}

document.addEventListener('DOMContentLoaded', function(){
    window.animations.init();

    setTimeout(() => {
        window.animations.update()
    }, 10);
})
            
document.addEventListener('scroll', function() {
    window.animations.documentHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight);
    window.animations.windowHeight = window.innerHeight;
    window.animations.update()
})