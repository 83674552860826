import Gator from 'gator';
import validatorServices from '@components/validator/services.js';
import validatorView from '@components/validator/view.js';

const INVALID_CLASS = 'form__field--invalid';

const ERROR_VISIBILITY_CLASS = 'form__error--visible';

const VALIDATION_SERVICES_MAP = {
  letters: ({ value, min, max }) => validatorServices.validateLetters(value, min, max),
  number: ({ value, min, max }) => validatorServices.validateNumber(value, min, max),
  text: ({ value, min, max }) => validatorServices.validateText(value, min, max),
  phone: ({ value }) => validatorServices.validatePhone(value),
  email: ({ value }) => validatorServices.validateEmail(value),
  check: ({ value }) => validatorServices.validateCheckbox(value),
};

const validateField = (input) => {
  const inputBox = input.closest('[data-entity="v-field-box"]');
  const inputError = inputBox.querySelector('[data-entity="v-field-error"]');
  const inputValue = input.type !== 'checkbox' ? input.value : input.checked;

  const validator = {
    name: input.dataset.validator,
    options: {
      min: +input.dataset.min || 2,
      max: +input.dataset.max || 200,
    },
  };
  const { name: validatorName, options: validatorOptions } = validator;
  const validationService = VALIDATION_SERVICES_MAP[validatorName];

  const validationState = validationService({
    value: inputValue,
    ...validatorOptions,
  });

  validatorView.renderField(validationState, inputBox, INVALID_CLASS);

  if (inputError) {
    validatorView.renderError(validationState, inputError, ERROR_VISIBILITY_CLASS, 'Поле заполнено некорректно');
  }

  return validationState;
};

const validateForm = (form) => {
  let formValidationState = 'idle';
  const fields = Array.from(form.querySelectorAll('[data-entity="v-field"]'));

  fields.forEach((input) => {
    const fieldValidationState = validateField(input);

    if (fieldValidationState === 'invalid') {
      formValidationState = 'invalid';
    }
  });

  return formValidationState;
};

const registerFormValidator = () => {
  if (window.spiks) {
    window.spiks.FormManager = {
      attachEvents: (formNode) => {
        Gator(formNode).on('input', '[data-entity="v-field"]', ({ target }) => {
          validateField(target);
        });
      },
      validate: (formNode) => validateForm(formNode) !== 'invalid',
    };
  }
};

export default registerFormValidator;
