import {Modal} from 'bootstrap';

const modals = document.querySelectorAll('.modal')
const modalCallback = new Modal(document.querySelector('#callback'));

// window.modals.list =  document.querySelectorAll('.modal');

document.addEventListener('DOMContentLoaded', function(){

    window.modal = {
        list: document.querySelectorAll('.modal'),
    }
    
    for (let index = 0; index < window.modal.list.length; index++) {
        const item = window.modal.list[index];
        const id = item.getAttribute('id')
        const itemModal = new Modal(item);

        window.modal[id] = itemModal;
    }
    
    window.modal.list.forEach(function(e) {
        e.addEventListener('shown.bs.modal', function () {
    		if (this.querySelector('[autofocus]')){
    			this.querySelector('[autofocus]').focus();
    		}
    	})
    });

    // console.log(window.modal);

    // window.modal.callback.show()
    // window.modal.chanels.show()
    // window.modal.success.show()
    // window.modal.id.hide()
})
