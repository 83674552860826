const renderField = (validationState, node, invalidClass) => {
  if (validationState === 'valid') {
    node.classList.remove(invalidClass);
  }

  if (validationState === 'invalid') {
    node.classList.add(invalidClass);
  }
};

const renderError = (validationState, node, visibilityClass, message = '') => {
  if (validationState === 'valid') {
    node.classList.remove(visibilityClass);
    node.textContent = '';
  }

  if (validationState === 'invalid') {
    node.classList.add(visibilityClass);
    node.textContent = message;
  }
};

const validatorView = {
  renderField,
  renderError,
};

export default validatorView;
