import * as yup from 'yup';

const MSG_LETTERS = 'Поле не может содержать цифры';
const MSG_REQUIRED = 'Поле обязательно для заполнения';
const MSG_TEXT = 'Поле не должно содержать цифры';
const MSG_TEXT_MIN = 'Значение не может быть таким коротким';
const MSG_TEXT_MAX = 'Значение не может быть таким длинным';
const MSG_PHONE = 'Телефон заполнен не верно';
const MSG_EMAIL = 'Email заполнен не верно';
const MSG_NUMBER = 'Поле должно содержать цифры';
const MSG_NUMBER_MIN = 'Значение не может быть меньше';
const MSG_NUMBER_MAX = 'Значение не может быть больше';

const validateLetters = (value, min, max) => {
  const lettersRegExp = /^[A-ZА-Яa-zа-я\s]*$/;

  const scheme = yup
    .string()
    .trim()
    .required(MSG_REQUIRED)
    .matches(lettersRegExp, MSG_LETTERS)
    .min(min, MSG_TEXT_MIN)
    .max(max, MSG_TEXT_MAX);

  return scheme.isValidSync(value) ? 'valid' : 'invalid';
};

const validateText = (value, min, max) => {
  const scheme = yup
    .string(MSG_TEXT)
    .trim()
    .required(MSG_REQUIRED)
    .min(min, MSG_TEXT_MIN)
    .max(max, MSG_TEXT_MAX);

  return scheme.isValidSync(value) ? 'valid' : 'invalid';
};

const validatePhone = (value) => {
  const phoneRegExp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/;

  const scheme = yup
    .string()
    .trim()
    .required(MSG_REQUIRED)
    .matches(phoneRegExp, MSG_PHONE);
  return scheme.isValidSync(value) ? 'valid' : 'invalid';
};

const validateEmail = (value) => {
  const scheme = yup
    .string(MSG_TEXT)
    .trim()
    .required(MSG_REQUIRED)
    .email(MSG_EMAIL);

  return scheme.isValidSync(value) ? 'valid' : 'invalid';
};

const validateNumber = (value, min, max) => {
  const scheme = yup
    .number(MSG_NUMBER)
    .required(MSG_REQUIRED)
    .min(min, MSG_NUMBER_MIN)
    .max(max, MSG_NUMBER_MAX);

  return scheme.isValidSync(value) ? 'valid' : 'invalid';
};

const validateCheckbox = (value) => {
  const scheme = yup
    .boolean()
    .oneOf([true]);

  return scheme.isValidSync(value) ? 'valid' : 'invalid';
};

const validatorServices = {
  validateLetters,
  validateText,
  validatePhone,
  validateEmail,
  validateNumber,
  validateCheckbox,
};

export default validatorServices;
